import * as React from "react";

// Assets
import appdev from "../../img/Appdev.svg";

function SvgComponent(props) {
  return (
    <img src={appdev} />
  );
}

export default SvgComponent;
