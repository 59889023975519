import * as React from "react";

// Assets
import LogoImage from "../../assets/img/D1S_Black.png";


function LogoComponent(props) {
  return (
    <img src={LogoImage}  width={140} alt="logo_icon"></img>
  );
}

export default LogoComponent;
