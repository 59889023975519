import * as React from "react";

// Assets
import webdev from "../../img/Programmingweb.svg";

function SvgComponent(props) {
  return (
    <img src={webdev} />
  );
}

export default SvgComponent;
