import * as React from "react";

// Assets
import branding from "../../img/branding.svg";

function SvgComponent(props) {
  return (
    <img src={branding} />
  );
}

export default SvgComponent;
