import * as React from "react";

// Assets
import sfdev from "../../img/cloud.svg";

function SvgComponent(props) {
  return (
    <img src={sfdev} />
  );
}

export default SvgComponent;
